import { WalletIcon } from '@shared/assets';
import { useConnectWallet } from '@shared/hooks';

const MobileConnectButton = () => {
  const { handleConnect, isReconnecting } = useConnectWallet();

  return (
    <button
      className="roundedBlTr flexCenter size-10 cursor-pointer border-2 border-neutral700 md:hidden"
      onClick={handleConnect}
      disabled={isReconnecting}
    >
      <WalletIcon />
    </button>
  );
};

export default MobileConnectButton;
