import { twMerge } from 'tailwind-merge';

import { useConnectWallet } from '@shared/hooks';

const DesktopConnectButton = () => {
  const { handleConnect, isReconnecting, isOpen } = useConnectWallet();

  return (
    <button
      className={twMerge(
        'connectButton font-small roundedBlTr md:flexCenter relative z-[1] hidden h-10 w-fit shrink-0 cursor-pointer overflow-hidden px-3 font-bold uppercase text-neutral400',
        isOpen ? 'continueAnimation' : ''
      )}
      onClick={handleConnect}
      disabled={isReconnecting}
    >
      Connect wallet
    </button>
  );
};

export default DesktopConnectButton;
