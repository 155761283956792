import { useState } from 'react';

import { twMerge } from 'tailwind-merge';

import BuyAitechModal from '@features/marketplace/buyAitech/BuyAitechModal';

interface IProps {
  className?: string;
  buttonClassName?: string;
}

const BuyAitechButton = ({ className, buttonClassName }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={className}>
      <div>
        <button
          className={twMerge(
            'font-small roundedBlTr md:flexCenter relative z-[1] h-10 w-fit shrink-0 cursor-pointer overflow-hidden bg-green500 px-3 font-bold uppercase text-neutral900',
            buttonClassName
          )}
          onClick={() => setIsOpen(true)}
        >
          Buy AITECH
        </button>
      </div>

      {isOpen && <BuyAitechModal isOpen={isOpen} setIsOpen={setIsOpen} />}
    </div>
  );
};

export default BuyAitechButton;
